import React, {useRef, useState} from 'react';
import {ReactComponent as MenuBars} from '../assets/images/menu-stack.svg';
import {ReactComponent as MenuClose} from '../assets/images/menu-close.svg';

const Header = (props) => {
        // console.log(props)

        const mobileMenu = useRef(null)
        const [menuState, toggleMenuState] = useState("")
        
        const toggleMenu = () => {
            if(menuState === "open") {
                toggleMenuState("");
            }
            else {
                toggleMenuState("open");
            }
            return false;
        }

        function executeScroll(id) {
            document.getElementById(id).scrollIntoView({block: 'start', behavior: "smooth"});
            toggleMenuState("");
        }

        return (
            <div className={"header " +props.stickyState}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-5 logo">
                            <a href="/"><img src={props.data.primary.logo.url} alt={props.data.primary.logo.alt} /></a>
                        </div> 
                        <div className="col-7 desktop text-right">
                                <ul>
                                {props.data.items.map((link, index)=>{
                                    return (
                                        <li key={index} onClick={()=> executeScroll(link.link_id)}>{link.link_name}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="col-5 mobile text-right">
                                <ul>
                                    <li onClick={toggleMenu} className="menu-bars">
                                        {menuState==="" ?
                                            <MenuBars stroke="#ffffff" />
                                        : null}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                <div className={"product-menu mobile "+menuState} ref={mobileMenu}>
                <div className="close-menu">
                    <MenuClose stroke="#ffffff" />
                </div>
                <ul>
                    {props.data.items.map((link, index)=>{
                        return (
                                <li key={index} onClick={()=> executeScroll(link.link_id)}>{link.link_name}</li>
                            )
                    })}
                </ul>
                </div>
                </div>
            );
}

export default Header;